import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faSatellite } from '@fortawesome/free-solid-svg-icons';

mapboxgl.accessToken = 'pk.eyJ1IjoiYW5hc2JkOTQiLCJhIjoiY20xaHh0eGJqMGlqMTJzcXliNW5jb3lrbCJ9.XReXwIiZ8x99pzQPrsQ6Vg';

const MapboxComponent = ({ onModuleSelect, token }) => {
  const mapRef = useRef(null);
  const [map, setMap] = useState(null);
  const [moduleLocations, setModuleLocations] = useState([]);
  const [currentLayer, setCurrentLayer] = useState('satellite');

  const calculateCenter = (locations) => {
    if (locations.length === 0) return { lat: 37.0902, lng: -95.7129 }; // Default to the center of the US
    const latitudes = locations.map((loc) => parseFloat(loc.latitude));
    const longitudes = locations.map((loc) => parseFloat(loc.longitude));
    const avgLat = latitudes.reduce((sum, lat) => sum + lat, 0) / latitudes.length;
    const avgLng = longitudes.reduce((sum, lng) => sum + lng, 0) / longitudes.length;
    return { lat: avgLat, lng: avgLng };
  };

  const initMap = (center) => {
    if (mapRef.current && !map) {
      const mapboxMap = new mapboxgl.Map({
        container: mapRef.current,
        style: 'mapbox://styles/mapbox/satellite-v9',
        center: [center.lng, center.lat], 
        zoom: 14, 
        minZoom: 2,
        maxZoom: 19,
      });

      mapboxMap.addControl(new mapboxgl.NavigationControl());

      const draw = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          polygon: true,
          trash: true,
        },
      });
      mapboxMap.addControl(draw);

      mapboxMap.on('style.load', () => {
        mapboxMap.addSource('mapbox-dem', {
          type: 'raster-dem',
          url: 'mapbox://mapbox.mapbox-terrain-dem-v1',
          tileSize: 512,
          maxzoom: 14,
        });
        mapboxMap.setTerrain({ source: 'mapbox-dem', exaggeration: 1.5 });

        mapboxMap.addLayer({
          id: 'sky',
          type: 'sky',
          paint: {
            'sky-type': 'atmosphere',
            'sky-atmosphere-sun': [0.0, 0.0],
            'sky-atmosphere-sun-intensity': 15,
          },
        });
      });

      setMap(mapboxMap);
    }
  };

  const addMarkers = (map, locations) => {
    if (map) {
      locations.forEach((module) => {
        const markerElement = document.createElement('div');
        markerElement.style.backgroundColor = `#${module.color}`;
        markerElement.style.color = 'white';
        markerElement.style.borderRadius = '50%';
        markerElement.style.width = '40px';
        markerElement.style.height = '40px';
        markerElement.style.display = 'flex';
        markerElement.style.justifyContent = 'center';
        markerElement.style.alignItems = 'center';
        markerElement.innerHTML = `<strong>${module.marker}°C</strong>`; 

        const marker = new mapboxgl.Marker(markerElement)
          .setLngLat([parseFloat(module.longitude), parseFloat(module.latitude)])
          .setPopup(new mapboxgl.Popup().setHTML(`<h3>${module.module_name}</h3><p>Temperature: ${module.marker}°C</p>`)) 
          .addTo(map);

        marker.getElement().addEventListener('click', () => {
          onModuleSelect(module.module_code);
        });
      });
    }
  };

  const toggleMapView = () => {
    if (map) {
      if (currentLayer === 'satellite') {
        map.setStyle('mapbox://styles/mapbox/streets-v11');
        setCurrentLayer('streets');
      } else {
        map.setStyle('mapbox://styles/mapbox/satellite-v9');
        setCurrentLayer('satellite');
      }
    }
  };

  const fetchModuleLocations = async () => {
    try {
      const response = await fetch(
        `https://api.agriguard.app/api-sh/agrimodules?symbol=modules&lang=fr&token=${token}`
      ); 
      const data = await response.json();
      setModuleLocations(data);
    } catch (error) {
      console.error('Error fetching module locations:', error);
    }
  };

  useEffect(() => {
    fetchModuleLocations();
  }, [token]); 

  useEffect(() => {
    if (moduleLocations.length > 0) {
      const center = calculateCenter(moduleLocations); 
      initMap(center); 
      if (map) {
        addMarkers(map, moduleLocations); 
      }
    }
  }, [moduleLocations, map]);

  return (
    <div style={{ position: 'relative', width: '100%', maxWidth: '800px', margin: '20px auto' }}>
      <div
        ref={mapRef}
        style={{
          width: '100%',
          height: '400px',
          border: '3px solid #3498db',
          borderRadius: '15px',
          overflow: 'hidden',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        }}
      />

      <div style={{ position: 'absolute', bottom: '20px', right: '20px' }}>
        <div style={{ position: 'relative' }}>
          <button
            onClick={toggleMapView}
            style={{
              backgroundColor: '#3498db',
              color: '#fff',
              border: 'none',
              borderRadius: '5px',
              padding: '10px 15px',
              cursor: 'pointer',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <FontAwesomeIcon
              icon={currentLayer === 'satellite' ? faMapMarkerAlt : faSatellite}
              style={{ marginRight: '8px', fontSize: '18px' }}
            />
            {currentLayer === 'streets' ? '' : ''} 
          </button>
        </div>
      </div>
    </div>
  );
};

export default MapboxComponent;
