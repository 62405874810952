import React, { useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import axios from 'axios';

const TemperatureInversionChart = ({ moduleCode, paramCode, widgetColor, token }) => {
  const [chartData, setChartData] = useState([]);
  const [paramData, setParamData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const chartResponse = await axios.get('https://api.agriguard.app/api-sh/agrichart', {
          params: {
            symbol: moduleCode,
            market: paramCode,
            lang: 'fr',
            token: token, // Use the dynamic token passed as a prop
          },
        });
        const transformedChartData = chartResponse.data.map(item => ({
          date: new Date(item.chart_item).toLocaleDateString('fr-FR', {
            month: 'numeric',
            day: 'numeric',
          }),
          value1: Number(item.chart_value1),
          value2: Number(item.chart_value2),
        }));
        setChartData(transformedChartData);

        const paramResponse = await axios.get('https://api.agriguard.app/api-sh/agriparams', {
          params: {
            symbol: moduleCode,
            lang: 'fr',
            token: token, // Use the dynamic token passed as a prop
          },
        });

        const parameter = paramResponse.data.find(param => param.param_code === paramCode);
        setParamData(parameter);
        setIsLoading(false);
      } catch (error) {
        console.error('Erreur lors du chargement des données:', error);
        setError('Erreur lors du chargement des données.');
        setIsLoading(false);
      }
    };

    if (moduleCode && paramCode && token) {
      fetchData();
    }
  }, [moduleCode, paramCode, token]); // Add token as a dependency

  if (isLoading) {
    return <p>Chargement des données...</p>;
  }

  if (error) {
    return <p className="text-red-500">{error}</p>;
  }

  return (
    <div style={{ ...styles.widget, backgroundColor: widgetColor || '#f5f5f5' }}>
      <h3 style={styles.title}>{paramData?.param_title || 'Temperature Inversion'}</h3>

      <div style={styles.chartContainer}>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Line type="monotone" dataKey="value1" stroke="#8884d8" activeDot={{ r: 8 }} />
            <Line type="monotone" dataKey="value2" stroke="#82ca9d" />
          </LineChart>
        </ResponsiveContainer>
      </div>

      <div
        style={{
          ...styles.paramContainer,
          backgroundColor: `#${paramData?.color || 'FF6F6F'}`,
        }}
      >
        <h2 style={styles.paramValue}>
          {paramData?.param_value || 'no data available'}
        </h2>
        <p style={styles.paramText}>
          {paramData?.param_text || 'no data available'}
        </p>
      </div>
    </div>
  );
};

const styles = {
  widget: {
    padding: '20px',
    margin: '10px auto',
    borderRadius: '30px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    maxWidth: '800px',
    width: '100%',
    boxSizing: 'border-box',
    textAlign: 'center',
    backgroundColor: '#E0F7FA',
  },
  title: {
    textAlign: 'center',
    fontSize: '1.5rem',
    marginBottom: '10px',
    color: '#000',
  },
  chartContainer: {
    width: '100%',
    marginBottom: '10px',
  },
  paramContainer: {
    padding: '20px',
    color: 'white',
    textAlign: 'center',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    width: '100%',
    margin: '0 auto',
    maxWidth: '800px',
    backgroundColor: '#FF6F6F',
  },
  paramValue: {
    margin: '0 10px',
    fontSize: '2rem',
  },
  paramText: {
    margin: '0',
    fontSize: '1rem',
  },
};

export default TemperatureInversionChart;
