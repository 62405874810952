import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import Header from './Header';
import LineChartComponent from './LineChartComponent';
import TemperatureInversionChart from './TemperatureInversionChart';
import MapboxComponent from './MapboxComponent';

function Dashboard() {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedModule, setSelectedModule] = useState('A01');
  const [token, setToken] = useState(null);
  const [moduleParams, setModuleParams] = useState([]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const newToken = queryParams.get('token');
    
    // Redirect to login if no token is present
    if (!newToken) {
      navigate('/'); // Redirect to login page
      return;
    }
    
    if (newToken && newToken !== token) {
      setToken(newToken);
    }
  }, [location, token, navigate]);

  useEffect(() => {
    const fetchParams = async () => {
      try {
        const response = await axios.get('https://api.agriguard.app/api-sh/agriparams', {
          params: {
            symbol: selectedModule,
            lang: 'fr',
            token: token,
          },
        });

        console.log('Response Status:', response.status);

        if (response.status === 401) {
          navigate('/');
          return;
        }

        const params = response.data.map((param) => param.param_code);
        setModuleParams(params);
      } catch (error) {
        console.error('Error fetching module params:', error);
        if (error.response) {
          console.log('Error Response Status:', error.response.status);

          if (error.response.status === 401) {
            navigate('/');
          }
        }
      }
    };

    if (selectedModule && token) {
      fetchParams();
    }
  }, [selectedModule, token, navigate]);

  return (
    <div className="p-4 md:p-8 bg-gray-100 min-h-screen flex flex-col justify-between">
      <Header onModuleChange={(module) => setSelectedModule(module)} token={token} />
      <div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="col-span-1 md:col-span-2">
          <MapboxComponent onModuleSelect={(module) => setSelectedModule(module)} className="w-full h-auto" token={token} />
        </div>
      </div>
      <div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-4">
        {moduleParams
          .filter((paramCode) => paramCode !== 'TI')
          .map((paramCode, index) => (
            <LineChartComponent key={index} moduleCode={selectedModule} paramCode={paramCode} widgetColor="#dddddd7d" token={token} />
          ))}
      </div>
      <div className="mt-6">
        {moduleParams.includes('TI') && (
          <TemperatureInversionChart moduleCode={selectedModule} paramCode="TI" widgetColor="#dddddd7d" token={token} />
        )}
      </div>
    </div>
  );
}

export default Dashboard;
