import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

function LineChartComponent({ moduleCode, paramCode, widgetColor, token }) {
  const [chartData, setChartData] = useState([]);
  const [paramData, setParamData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (paramCode === 'TI') {
          console.log(`Excluding paramCode: ${paramCode}`);
          return;
        }

        
        const chartResponse = await axios.get(`https://api.agriguard.app/api-sh/agrichart`, {
          params: {
            symbol: moduleCode,
            market: paramCode,
            lang: 'fr',
            token: token, 
          },
        });

        const transformedChartData = chartResponse.data.map((item) => ({
          date: new Date(item.chart_item).toLocaleDateString('fr-FR', {
            month: 'numeric',
            day: 'numeric',
          }),
          value: Number(item.chart_value1),
        }));
        setChartData(transformedChartData);

        const paramResponse = await axios.get(`https://api.agriguard.app/api-sh/agriparams`, {
          params: {
            symbol: moduleCode,
            lang: 'fr',
            token: token, 
          },
        });

        const parameter = paramResponse.data.find((param) => param.param_code === paramCode);
        setParamData(parameter);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [moduleCode, paramCode, token]); 

  if (paramCode === 'TI') return null;
  if (!paramData) return <div>Loading...</div>;

  return (
    <div style={{ ...styles.widget, backgroundColor: widgetColor || '#f5f5f5' }}>
      <h3 style={styles.title}>{paramData.param_title}</h3>
      <div style={styles.chartContainer}>
        <ResponsiveContainer width="100%" height={250}>
          <LineChart data={chartData} margin={{ top: 5, right: 30, left: 0, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Line type="monotone" dataKey="value" stroke={`#${paramData.color}`} activeDot={{ r: 8 }} />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div
        style={{
          ...styles.paramContainer,
          backgroundColor: `#${paramData.color}`,
        }}
      >
        <h2 style={styles.paramValue}>{paramData.param_value}</h2>
        <p style={styles.paramText}>{paramData.param_text}</p>
      </div>
    </div>
  );
}

const styles = {
  widget: {
    padding: '20px',
    margin: '10px auto',
    borderRadius: '30px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    maxWidth: '800px',
    width: '100%',
    boxSizing: 'border-box',
    textAlign: 'center',
    backgroundColor: '#E0F7FA',
  },
  title: {
    textAlign: 'center',
    fontSize: '1.5rem',
    marginBottom: '10px',
    color: '#000',
  },
  chartContainer: {
    width: '100%',
    marginBottom: '10px',
  },
  paramContainer: {
    padding: '20px',
    color: 'white',
    textAlign: 'center',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    width: '100%',
    margin: '0 auto',
    maxWidth: '800px',
    backgroundColor: '#FF6F6F',
  },
  paramValue: {
    margin: '0 10px',
    fontSize: '2rem',
  },
  paramText: {
    margin: '0',
    fontSize: '1rem',
  },
};

export default LineChartComponent;
